<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Warehouse</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text to="/inventory/warehouses">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor
          :initial="dataItem"
          :createStatus="createStatus"
          @data="save"
        ></editor>
        <!-- end -->
      </v-card-text>
    </v-card>

    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    createStatus: false,
  }),
  watch: {
    "$route.params.id": {
      handler: "getItemGroup",
      immediate: true,
    },
  },
  methods: {
    save(data) {
      const whs1 = data.document_lines;
      data.whs1 = whs1;
      delete data.document_lines;
      const url = `/warehouse/${data.id}`;
      // const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then(() => {
          location.reload();
        })
        .catch(err => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getItemGroup(id) {
      const self = this;
      this.$store
        .dispatch("get", `/warehouse/${id}`)
        .then((res) => {
          self.dataItem = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>