<template>
  <v-container>
    <v-tabs background-color="primary" dark dense>
      <v-tab active-class="info"> General </v-tab>
      <v-tab active-class="info"> Accounting</v-tab>
      <v-tab active-class="info"> Bin Locations </v-tab>
      <v-tab-item>
        <v-card>
          <v-form>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  label="Warehouse Code"
                  v-model="record.WhsCode"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  label="Warehouse Name"
                  v-model="record.WhsName"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  outlined
                  dense
                  v-model="record.BPLId"
                  :items="branches"
                  item-text="BPLName"
                  item-value="BPLId"
                  label="Branch"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  outlined
                  dense
                  v-model="record.BinActivat"
                  label="Bin Activated"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  outlined
                  dense
                  v-model="record.External"
                  :items="ExtAnswers"
                  item-text="text"
                  item-value="value"
                  label="Externally Managed"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-dialog v-model="dialog" max-width="800px">
                <v-card>
                  <v-toolbar color="accent" dense dark>
                    <v-toolbar-title>List of Accounts</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click.native="dialog = false" icon>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <v-card-text>
                    <v-row>
                      <v-data-table
                        hide-default-footer
                        :headers="glHeaders"
                        :items="glAccounts"
                        show-select
                        :single-select="singleSelect"
                        v-model="selected"
                        @item-selected="clickedItem"
                      >
                      </v-data-table>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <v-data-table hide-default-footer :headers="headers" :items="accounts">
              <template v-slot:item.Code="{ item }">
                <v-text-field
                  style="width: 200px"
                  single-line
                  outlined
                  dense
                  v-model="item.Code"
                  :append-icon="'mdi-airballoon'"
                  @click:append="openModal(item)"
                ></v-text-field>
              </template>

              <template v-slot:item.AcctName="{ item }">
                <span>{{ item.AcctName }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-row class="mt-4">
            <v-col cols="3">
              <v-text-field
                outlined
                dense
                label="Bin Separator"
                v-model="record.BinSeptor"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                outlined
                dense
                v-model="record.DftBinEnfd"
                label="Default Bin Enforced"
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                outlined
                dense
                v-model="record.RecBinEnab"
                label="Enable Receiving Bin Locations"
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.DftBinAbs"
                item-text="BinCode"
                item-value="AbsEntry"
                :items="BinLocations"
                label="Default Bin"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                outlined
                dense
                v-model="record.RecvMaxWT"
                label="Recv. up to Max. Weight"
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                outlined
                dense
                v-model="record.RecvMaxQty"
                label="Recv. up to Max. Qty"
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.AutoIssMtd"
                item-text="text"
                item-value="value"
                :items="AutoIssMtdOpt"
                label="Auto. Issue Method"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.AutoRecvMd"
                item-text="text"
                item-value="value"
                :items="binLocationOptions"
                label="Auto. Receipt Method"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <v-col cols="12">
      <v-btn
        v-if="createStatus == true"
        color="accent"
        @click="sendData"
        :loading="loader"
      >
        <v-icon left>mdi-content-save</v-icon>Save
      </v-btn>
      <v-btn v-else color="accent" @click="sendData" :loading="loader">
        <v-icon left>mdi-content-save</v-icon>Update
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
    createStatus: {
      type: Boolean,
    },
  },
  data: () => ({
    singleSelect: true,
    selected: [],
    dialog: false,
    loader: false,
    record: {},
    accounts: [],
    glAccounts: [],
    branches: [],
    selectedId: null,
    BinLocations: [],
    headers: [
      { text: "Type of Account", value: "Description" },
      { text: "Account Code", value: "Code" },
      { text: "Account Name", value: "AcctName" },
    ],
    glHeaders: [
      { text: "Account Number", value: "AcctCode" },
      { text: "Account Name", value: "AcctName" },
      { text: "Balance", value: "CurrTotal" },
    ],
    ExtAnswers: [
      { text: "Yes", value: "Y" },
      { text: "No", value: "N" },
    ],
    date: new Date().toISOString().substr(0, 10),
    AutoIssMtdOpt: [
      { value: "0", text: "Single Choice" },
      { value: "1", text: "Bin Location Code Order" },
      { value: "2", text: "Alternative Sort Code Order" },
      { value: "3", text: "Descending Quantity" },
      { value: "4", text: "Ascending Quantity" },
      { value: "5", text: "FIFO" },
      { value: "6", text: "LIFO" },
      { value: "7", text: "Ascending Quantity - Single Bin Preferred" },
    ],
    binLocationOptions: [
      { value: "0", text: "Default Bin Location" },
      { value: "1", text: "Last Bin Location That Received Item" },
      { value: "2", text: "Item's Current Bin Locations" },
      { value: "3", text: "Item's Current and Historical Bin Locations" },
    ],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
      if (this.createStatus == false) {
        this.accounts = val.whs1;
      }
      this.getBinLocations();
    },
    setDate(date) {
      this.record.date = date;
    },
    openModal(item) {
      this.dialog = true;
      this.selectedId = item.id;
    },
    clickedItem($event) {
      const account = $event.item;
      const id = this.selectedId;
      const activeRecord = this.accounts.find((record) => {
        return record.id === id;
      });

      const pos = this.accounts.indexOf(activeRecord);
      if (pos === -1) {
        return;
      }
      activeRecord.Code = account.AcctCode;
      activeRecord.AcctCode = account.id;
      activeRecord.AcctName = account.AcctName;
      this.accounts[pos] = activeRecord;
      this.selectedId = null;
      this.selected = [];
      this.dialog = false;
    },
    sendData() {
      let data = this.record;
      data.document_lines = this.accounts;
      this.loader = true;
      this.$emit("data", data);
    },
    getAccounts() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/gl_account_determination`)
        .then((res) => {
          if (self.createStatus == true) {
            self.accounts = res.ResponseData.acp10;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getActiveGLAccounts() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/activeGLaccounts`)
        .then((res) => {
          self.glAccounts = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getBranches() {
      const self = this;
      this.$store
        .dispatch("get", `/branches`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.branches = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getBinLocations() {
      const self = this;
      this.loading = true;
      if (this.record.WhsCode) {
        this.$store
          .dispatch("get", `/warehouses/bin-location?WhsCode=${this.record.WhsCode}`)
          .then((res) => {
            if (res.ResultCode == 1200) {
              console.log(res.ResponseData);
              self.BinLocations = res.ResponseData;
              self.loading = false;
            }
            if (res.ResultCode == 1043) {
              this.$refs.snackbar.show(res.ResultDesc, "red");
              self.loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.$refs.snackbar.show(err, "red");
            self.loading = false;
          });
      }
    },
  },
  created() {
    this.getAccounts();
    this.getActiveGLAccounts();
    this.getBranches();
    this.getBinLocations();
  },
};
</script>
